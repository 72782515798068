@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

:root {
  --primary-theme-color: #8c0200;
  --primary-text-color: #424242;
}

body {
  font-family: 'Roboto', sans-serif;
  color: var(--primary-text-color);
}

main {
  height: 100vh;
  max-height: 100vh;
}

.h100 {
  height: 100vh;
}

.konfisio-color {
  color: var(--primary-theme-color);
}

.konfisio-color-bg {
  background: var(--primary-theme-color);
}

.konfisio-color-outline {
  border-color: var(--primary-theme-color);
  color: var(--primary-theme-color);
}

.konfisio-color-outline:hover {
  background-color: var(--primary-theme-color);
  color: white;
}

.titles {
  font-size: 30px;
  color: var(--primary-theme-color);
}


.hr-konfisio {
  color: var(--primary-theme-color);
}

#agenda,
#posgraduacao {
  scroll-margin: 85px;
}

.agendalink {
  font-weight: 400;
  font-size: 17px;
  position: relative;
  color: var(--primary-text-color);
  text-decoration: none;
  line-height: 2;

}

.agendalink:hover {
  color: var(--primary-theme-color);
}

.agendalink:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--primary-theme-color);
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.agendalink:hover:before {
  visibility: visible;
  width: 100%;
}

.toast {
  margin-top: 80px !important;
}

/* .toast-top-full-width {
  width: 40% !important;
} */