.navbar {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.ul-konfisio-painel>li>a {
  position: relative;
  color: #424242;

}

.ul-konfisio-painel>li>a:hover {
  color: #0d6efd;
}

.ul-konfisio-painel>li>a:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0d6efd;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.ul-konfisio-painel>li>a:hover:before {
  visibility: visible;
  width: 100%;
}

.logout-button:hover {
  background-color: #dc3545;
  color: white;
  border: 1px solid #dc3545;
}