/*Back to top*/
#backToTop {
  display: block;
  opacity: 0;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: var(--primary-theme-color);
  color: white;
  cursor: pointer;
  padding: 13px;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

#backToTop:hover {
  background-color: #555;
  transition: all 0.3s ease-in-out;
}

/*Back to top*/